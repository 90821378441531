import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { useFetcher, useSearchParams } from 'react-router'
import { t } from 'i18next'
import { Button } from '../button/button'
import { LinkButton } from '../button/link-button'
import { AuthContainerHeader } from '../container/auth-container'
import { ErrorList } from '../error-list/error-list'
import { ProviderConnectionForm } from '~/components/auth-form/provider-form'
import { StatusButton } from '~/components/button/status-button'
import { Field } from '~/components/forms/forms'
import { Link } from '~/components/internal-link/Internal-link'
import { type action } from '~/routes/auth/signup'
import { useClientLang } from '~/utils/i18n.utils'
import { useIsFetching } from '~/utils/misc'
import { signupSchema } from '~/types/auth'
import { HoneypotInputs } from 'remix-utils/honeypot/react'

export function SignupForm({
	handlePageChange,
}: {
	handlePageChange?: (page: number) => void
}) {
	const fetcher = useFetcher<typeof action>()
	const isPending = useIsFetching(fetcher)
	const lang = useClientLang().lngShort
	const [searchParams] = useSearchParams()
	const [form, fields] = useForm({
		id: 'signup-form',
		constraint: getZodConstraint(signupSchema),
		lastResult: fetcher?.data?.result,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: signupSchema })
		},
		defaultValue: {
			email: searchParams.get('email'),
		},
		shouldRevalidate: 'onInput',
	})

	return (
		<>
			{fetcher?.data?.result.status === 'success' ? (
				<>
					<AuthContainerHeader
						title={t('auth_thanks_for_register')}
						text={t('auth_check_email')}
						icon="check-badge"
					/>
					{handlePageChange === undefined && (
						<div className="text-center">
							<LinkButton variant="secondary" to="/">
								{t('action_back_to_homepage')}
							</LinkButton>
						</div>
					)}
				</>
			) : (
				<>
					<AuthContainerHeader
						title={
							<>
								{t('auth_create_account')}
								<span className="text-secondary">!</span>
							</>
						}
						text={t('auth_create_account_on_page')}
					/>
					<fetcher.Form method="POST" action="/signup" {...getFormProps(form)}>
						<HoneypotInputs />
						<input name="lang" value={lang} hidden readOnly />
						<Field
							labelProps={{
								htmlFor: fields.email.id,
								children: t('form_label_email'),
							}}
							inputProps={{
								...getInputProps(fields.email, { type: 'email' }),
								autoFocus: true,
							}}
							errors={fields.email.errors}
						/>
						<ErrorList errors={form.errors} id={form.errorId} />
						<StatusButton
							className="mt-4 w-full"
							status={isPending ? 'pending' : 'idle'}
							type="submit"
							disabled={isPending}
						>
							{t('action_create_account')}
						</StatusButton>
					</fetcher.Form>

					<ProviderConnectionForm />

					<div className="mx-auto mt-6 text-center">
						{handlePageChange !== undefined ? (
							<Button
								variant="link"
								type="button"
								onClick={() => handlePageChange(0)}
								className="hover:underline max-md:hidden"
							>
								{t('action_back_to_login')}
							</Button>
						) : (
							<Link className="text-center hover:underline" to="/login">
								{t('action_back_to_login')}
							</Link>
						)}
					</div>
				</>
			)}
		</>
	)
}
